var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"d-sm-flex align-sm-center justify-sm-space-between mb-6 elevation-0"},[_c('v-card',{staticClass:"pa-2 text-left elevation-0 order-0 d-flex align-center"},[_c('h2',[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-calendar")]),_vm._v(" EVENTOS")],1),_c('v-btn',{staticClass:"ml-3",attrs:{"fab":"","dark":"","color":"primary","to":{ name: 'EventoNew' }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-card',{staticClass:"pa-2 order-2",attrs:{"flat":"","width":"400"}},[_c('v-text-field',{staticClass:"text-right",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.eventos,"item-key":"_id","search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando... Espere","no-data-text":"Sin registros para mostrar","footer-props":{
							'items-per-page-options': [20, 50, 100, 200]
						},"items-per-page":20},scopedSlots:_vm._u([{key:"item.fechaInicio",fn:function(ref){
						var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.fechaInicio))+" ")]}},{key:"item.next",fn:function(ref){
						var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-switch',{attrs:{"color":"success"},on:{"change":function($event){return _vm.toogleNext(item)}},model:{value:(item.next),callback:function ($$v) {_vm.$set(item, "next", $$v)},expression:"item.next"}})],1)]}},{key:"item.active",fn:function(ref){
						var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-switch',{attrs:{"color":"success"},on:{"change":function($event){return _vm.toogleActive(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)]}},{key:"item.enVivo",fn:function(ref){
						var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-switch',{attrs:{"color":"success"},on:{"change":function($event){return _vm.toogleVivo(item)}},model:{value:(item.enVivo),callback:function ($$v) {_vm.$set(item, "enVivo", $$v)},expression:"item.enVivo"}})],1)]}},{key:"item.diferido",fn:function(ref){
						var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-switch',{attrs:{"color":"success"},on:{"change":function($event){return _vm.toogleDiferido(item)}},model:{value:(item.diferido),callback:function ($$v) {_vm.$set(item, "diferido", $$v)},expression:"item.diferido"}})],1)]}},{key:"item.action",fn:function(ref){
						var item = ref.item;
return [_c('v-icon',{staticClass:"botones_list",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editEvento(item)}}},[_vm._v(" mdi-chevron-double-right ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }