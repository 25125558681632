<template>
	<Layout>
		<v-container>
			<v-row>
				<v-col cols="12">
					<v-card class="d-sm-flex align-sm-center justify-sm-space-between mb-6 elevation-0">
						<v-card class="pa-2 text-left elevation-0 order-0 d-flex align-center">
							<h2> <v-icon large>mdi-calendar</v-icon> EVENTOS</h2>
							<v-btn fab dark color="primary" class="ml-3" :to="{ name: 'EventoNew' }">
								<v-icon dark>
									mdi-plus
								</v-icon>
							</v-btn>
						</v-card>
						<v-card class="pa-2 order-2" flat width="400">
							<v-text-field
									class="text-right"
									v-model="search"
									append-icon="mdi-magnify"
									label="Search"
									single-line
									hide-details
								/>
						</v-card>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-data-table
						:headers="headers"
						:items="eventos"
						item-key="_id"
						class="elevation-1"
						:search="search"
						:loading="loading"
						loading-text="Cargando... Espere"
						no-data-text="Sin registros para mostrar"
						:footer-props="{
							'items-per-page-options': [20, 50, 100, 200]
						}"
						:items-per-page="20"
					>

						<template v-slot:item.fechaInicio="{ item }">
							{{ dateFormat(item.fechaInicio) }}
						</template>

						<!-- template  -->
						<template v-slot:item.next="{ item }">
								<v-layout justify-center>
							<v-switch
								color="success"
								v-model="item.next"
                @change="toogleNext(item)"
							></v-switch>
								</v-layout>
						</template>

						<!-- template  Activo -->
						<template v-slot:item.active="{ item }">
								<v-layout justify-center>
							<v-switch
								color="success"
								v-model="item.active"
                @change="toogleActive(item)"
							></v-switch>
								</v-layout>
						</template>

						<!-- template En Vivo -->
						<template v-slot:item.enVivo="{ item }">
								<v-layout justify-center>
							<v-switch
								color="success"
								v-model="item.enVivo"
                @change="toogleVivo(item)"
							></v-switch>
								</v-layout>
						</template>

						<!-- template Diferido -->
						<template v-slot:item.diferido="{ item }">
								<v-layout justify-center>
							<v-switch
								color="success"
								v-model="item.diferido"
                @change="toogleDiferido(item)"
							></v-switch>
								</v-layout>
						</template>


						<!-- columna accion -->
						<template v-slot:item.action="{ item }">
							<v-icon  class="botones_list" color="primary"  @click="editEvento(item)">
								mdi-chevron-double-right
							</v-icon>
						</template>

					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</Layout>
</template>

<script>
	import axios from 'axios';
	import store from '@/store'
	import Helpers from '@/services/Helpers.js'
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	export default {
		name: 'EventList',
		data: () => ({
			eventos: [],
			search: '',
			loading: false,
			headers: [
				{ text: 'TITULO', align: 'start', value: 'titulo'},
				{ text: 'FECHA INICIO', align: 'start', value: 'fechaInicio'},
				{ text: 'PRÓXIMO', align: 'start', value: 'next'},
				{ text: 'ACTIVO', align: 'start', value: 'active'},
				{ text: 'EN VIVO', value: 'enVivo'},
				{ text: 'DIFERIDO', value: 'diferido'},
				{text: '', value: 'action', sortable: false, },
			],
		}),
		mounted(){
			this.getData()
			this.createBread()
		},
		methods: {
			async getData(){
				let me = this;
				try {
					const response = await axios.get('evento/list', configuration)
					me.eventos = response.data;
				} catch (e) {
					console.log(e.message)
				} finally {
					me.loading = false
				}
			},
			async toogleActive(item){
				let me = this;
        await axios.put('evento/toggle-active', {'_id': item._id, 'active': item.active},configuration)
          .then(
              function (response) {
                me.$store.commit('SET_ALERT_MESSAGE', {
                  show: true,
                  color: 'success',
                  message: 'Actualizado VISIBLE',
                  timeout: 4000
                }, {root: true})
              })
          .catch(function (e) {
            console.log(e.message)
          })
			},
			async toogleDiferido(item){
				let me = this;
        await axios.put('evento/toggle-diferido', {'_id': item._id, 'diferido': item.diferido},configuration)
          .then(
              function (response) {
                me.$store.commit('SET_ALERT_MESSAGE', {
                  show: true,
                  color: 'success',
                  message: 'Actualizado DIFERIDO',
                  timeout: 4000
                }, {root: true})
              })
          .catch(function (e) {
            console.log(e.message)
          })
			},

			async toogleNext(item){
				let me = this;
        await axios.put('evento/toggle-next', {'_id': item._id, 'next': item.next},configuration)
          .then(
              function (response) {
                me.$store.commit('SET_ALERT_MESSAGE', {
                  show: true,
                  color: 'success',
                  message: 'Actualizado PRÓXIMO',
                  timeout: 4000
                }, {root: true})
              })
          .catch(function (e) {
            console.log(e.message)
          })
			},
			async toogleVivo(item){
				let me = this;
        await axios.put('evento/toggle-vivo', {'_id': item._id, 'enVivo': item.enVivo},configuration)
          .then(
              function (response) {
                me.$store.commit('SET_ALERT_MESSAGE', {
                  show: true,
                  color: 'success',
                  message: 'Actualizado EN VIVO',
                  timeout: 4000
                }, {root: true})
              })
          .catch(function (e) {
            console.log(e.message)
          })
			},
			editEvento(item){
				this.$router.push({name: 'EventoEdit', params: {id: item._id}})
			},
			dateFormat(date){
				return Helpers.dateFormatEs(date)
			},
			createBread(){
				this.$store.dispatch('setBread',[
        {
          text: `Eventos`,
          disabled: true,
          href: '#',
        },
				]);
			},
		},
		computed: {
		}
	}
</script>

<style lang="scss" scoped>
	.botones_list {
		margin: 0px 10px 10px 0px;
			font-size: xx-large;
	}
</style>